*{
  box-sizing: border-box;
}
body{
 margin: 0;
 
/* font-family: 'Anton', sans-serif; */
  /* font-family: 'Roboto', sans-serif; */
  /* font-family: "po", serif; */
/* font-family: 'Poppins', sans-serif; */
font-family: "Merriweather", serif;
  background-color: #F3F4FA;
  color: #000000;
  
  /* cursor: pointer; */
/* background: rgb(238,174,202); */
/* background: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%); */
/* background: rgb(255,255,255); */
/* background: linear-gradient(234deg, rgba(255,255,255,1) 0%, rgba(193,216,242,1) 38%, rgba(148,187,233,1) 83%); */
}



