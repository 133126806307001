.projects{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.projects-con{
    width: 60%;
    height: 80vh;
    
}
.projects-1{
    margin-top: 12%;
    margin-bottom: 12%;
    font-size: 2em;
    text-align: center;
}
.projects-1 img{
 width: 100%;
 /* height: 70vh; */
 border-radius: 15px;
 margin-top: 3%;
 margin-bottom: 3%;
 font-size: large;
}
