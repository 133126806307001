.myjourney{
    margin-top: 3%;
}
.myjourney-header{
 font-size: 4em;
 font-weight: 900;
}
.myjourney-row1{
    margin-top: 3%;
}
.myjourney-num{
    font-size: 4em;
    font-weight: 900;
    display: flex;
    align-items: center;
    justify-content: center;
}
.myjourney-desc-head{
    font-size: 1.5em;
}
.myjourney-desc-head-last{
    font-size: 1.5em;
    margin-top: 2.5%;
}
.myjourney-desc{
    margin-top: 2%;
}
.myjourney-row2{
    margin-top: 10%;
}
.myjourney-row3{
    margin-top: 10%;
    margin-bottom: 10%;
}
.line-container {
    position: relative;
    /* Add any styling for your container here */
  }
  
  .vertical-line {
    position: absolute;
    background-color: #6E06F2; 
    width: 2px; 
    top: 0;
    bottom: 0;
    left: 50%; 
    transform: translateX(-50%); 
    transition: height 1.5s ease-in-out; 
    max-height: 12em;
    margin-top: 45%;
  }
  
  