.header {
  margin-top: 3.5%;
  overflow: hidden;
  height: 80vh;
  /* overflow: hidden; */
}
.desc{
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-col-1-desc {
  /* font-size: 50px; */
  /* font-family: "Anton", sans-serif; */
  /* font-family: 'Passion One', sans-serif; */
  /* font-weight: 900; */
  /* line-height: 80px; */
  /* letter-spacing: 2px; */
  overflow: hidden;
  text-align: left;
  /* margin-top: 20%; */
  /* margin-left: 18%; */
  /* display: flex;
  text-align: center;
  justify-content: center; */
}
.first{
font-size: 3.7em;
font-weight: 700;
color: #281950;
text-align: center;
}
.second{
  font-size: 1.5em;
  font-weight: 500;
  color: #281950;
  text-align: center;
}
.third{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.underline{
  position: absolute;
  margin-top: 1.2em;
  margin-left: -4.4em;
}
.airbln1{
  width: 140px;
  margin-top: 5em;
  /* margin-left: 70em; */
}
.airbln2{
  width: 120px;
  /* position: absolute; */
  margin-top: 23em;
  /* margin-left: 5em; */
}
.goto{
  font-size: 40px;
  /* font-family: "Anton", sans-serif; */
  /* font-family: 'Passion One', sans-serif; */
  font-weight: 300;
  /* line-height: 50px; */
  /* letter-spacing: 5px; */
  overflow: hidden;
  text-align: left;
  /* margin-top: 1%; */
  /* margin-left: 18%; */
  /* display: flex;
  text-align: center;
  justify-content: center; */
  color: #6E06F2;
  /* display: flex; */
}
.color{
  color: #6E06F2;
}
.button{
  margin-top: 5%;
  width: 200px;
  height: 45px;
  background-image: linear-gradient(to right,#b250f7, #6E06F2);
  border-radius: 30px;
  border: none;
  font-size: large;
}

.button:hover{
  color: black;
  background-color: white;
  border-color: black;
}
.desc-1 {
  animation-name: desc-1;
  animation-duration: 0.7s;
  transform: translate3d(0px, 19px, 0px);
}
.desc-1:hover{
  color: black;
  -webkit-text-fill-color: white; 
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}
@keyframes desc-1 {
  from {
    margin-left: -100%;
  }
  to {
    margin-left: 0%;
  }
}

.desc-2 {
  animation-name: desc-2;
  animation-duration: 1s;
}
.desc-2:hover{
  color: black;
  -webkit-text-fill-color: white; 
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}
@keyframes desc-2 {
  from {
    margin-left: -100%;
  }
  to {
    margin-left: 0%;
  }
}
.desc-3 {
  animation-name: desc-3;
  animation-duration: 1.5s;
}
.desc-3:hover{
  color: black;
  -webkit-text-fill-color: white; 
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}
@keyframes desc-3 {
  from {
    margin-left: -100%;
  }
  to {
    margin-left: 0%;
  }
}
.desc-4 {
  animation-name: desc-4;
  animation-duration: 2s;
}
.desc-4:hover{
  color: black;
  -webkit-text-fill-color: white; 
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}
@keyframes desc-4 {
  from {
    margin-left: -100%;
  }
  to {
    margin-left: 0%;
  }
}

.socials {
  margin-top: 230%;
  font-size: 1.2em;
  text-align: right;
  cursor: pointer;
  animation-name: socials;
  animation-duration: 2.5s;
  transition: font-size 1s;
}
@keyframes socials {
  from {
    margin-top: 370%;
  }
  to {
    margin-top: 230%;
  }
}

.mail a{
  text-decoration: none;
  font-size: 0.9em;
}
.image img{
  position: absolute;
  margin-top: 7%;
  margin-left: -20%;
  width: 230px;
  text-align: center;
}
.main-div{
  height: 82vh;
}
.right-div{
  display: flex;
  align-items: center;
  justify-content: center;
}
.c{
  width: 400px;
  height: 400px;
  border-style: solid;
  border-width: 1px;
  border-color: #6E06F2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.d img{
  /* width: 450px; */
  /* height: auto; */
  width: 300px;
  /* margin-top: 20%; */
  /* margin-left: -15%; */
  /* height: 200px; */
  /* width: 200px; */
  /* background-color: red; */
  /* border-top-right-radius: 50%;
  border-top-left-radius: 80%;
  border-bottom-left-radius: ; */
  /* border-radius: 40% 60% 60% 40% / 70% 30% 70% 30%; */
  border-radius: 50%;
}
.main-col-2-card-4{
  display: flex;
  flex-direction: row;
  /* margin-top: 30%; */
  align-items: center;
  justify-content: space-between;
  /* margin-right: 20%; */
}
.location-pin{
  width: 10px;
  height: 10px;
  color: #131313;
  background-color: #6E06F2;
  border-radius: 100%;
  filter: blur(0.7px);
  -webkit-filter: blur(0.7px);
  margin-right: 20px;
}
.location{
  display: flex;
  justify-content: center;
  align-items: center;
}
.socialss img{
width: 30px;
margin-left: 20px;
}
.socialss{
  display: flex;
  justify-content: space-evenly;
  align-self: flex-start;
}


.magicpattern { 
  position: absolute;
  z-index: -1;
  margin-top: -220px;
  margin-left: 220px;
  filter: blur(80px);
  -webkit-filter: blur(80px);
  width: 35%;
  height: 35%;
  background-size: cover;
  background-position:  center;
  background-repeat: repeat;
  background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox=%220 0 1000 1000%22 xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cdefs%3E%3ClinearGradient id=%22b%22 gradientTransform=%22rotate(-45 .5 .5)%22%3E%3Cstop offset=%22NaN%25%22 stop-color=%22%238ec5fc%22%2F%3E%3C%2FlinearGradient%3E%3CclipPath id=%22a%22%3E%3Cpath fill=%22currentColor%22 d=%22M799.5 671.5Q611 843 408.5 778T173 475.5q-33-237.5 199-267t424 131q192 160.5 3.5 332Z%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3Cg clip-path=%22url(%23a)%22%3E%3Cpath fill=%22url(%23b)%22 d=%22M799.5 671.5Q611 843 408.5 778T173 475.5q-33-237.5 199-267t424 131q192 160.5 3.5 332Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}
.magicpattern2 { 
  position: absolute;
  z-index: -1;
  margin-top: -220px;
  margin-left: -150px;
  filter: blur(110px);
  -webkit-filter: blur(110px);
  width: 42%;
  height: 35%;
  background-size: cover;
  background-position:  center;
  background-repeat: repeat;
  background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox=%220 0 1000 1000%22 xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cdefs%3E%3ClinearGradient id=%22b%22 gradientTransform=%22rotate(-45 .5 .5)%22%3E%3Cstop offset=%22NaN%25%22 stop-color=%22%238ec5fc%22%2F%3E%3C%2FlinearGradient%3E%3CclipPath id=%22a%22%3E%3Cpath fill=%22currentColor%22 d=%22M799.5 671.5Q611 843 408.5 778T173 475.5q-33-237.5 199-267t424 131q192 160.5 3.5 332Z%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3Cg clip-path=%22url(%23a)%22%3E%3Cpath fill=%22url(%23b)%22 d=%22M799.5 671.5Q611 843 408.5 778T173 475.5q-33-237.5 199-267t424 131q192 160.5 3.5 332Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

.magicpattern1 { 
  position: absolute;
  z-index: -1;
  margin-top: -220px;
  margin-left: 620px;
  filter: blur(80px);
  -webkit-filter: blur(80px);
  width: 35%;
  height: 35%;
  background-size: cover;
  background-position: center center;
  background-repeat: repeat;
  background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox=%220 0 1000 1000%22 xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cdefs%3E%3ClinearGradient id=%22b%22 gradientTransform=%22rotate(-45 .5 .5)%22%3E%3Cstop offset=%22NaN%25%22 stop-color=%22%23e0c3fc%22%2F%3E%3C%2FlinearGradient%3E%3CclipPath id=%22a%22%3E%3Cpath fill=%22currentColor%22 d=%22M799.5 671.5Q611 843 408.5 778T173 475.5q-33-237.5 199-267t424 131q192 160.5 3.5 332Z%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3Cg clip-path=%22url(%23a)%22%3E%3Cpath fill=%22url(%23b)%22 d=%22M799.5 671.5Q611 843 408.5 778T173 475.5q-33-237.5 199-267t424 131q192 160.5 3.5 332Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}
.magicpattern3 { 
  position: absolute;
  z-index: -1;
  margin-top: -220px;
  margin-left: 750px;
  filter: blur(110px);
  -webkit-filter: blur(110px);
  width: 42%;
  height: 35%;
  background-size: cover;
  background-position:  center;
  background-repeat: repeat;
  background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox=%220 0 1000 1000%22 xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cdefs%3E%3ClinearGradient id=%22b%22 gradientTransform=%22rotate(-45 .5 .5)%22%3E%3Cstop offset=%22NaN%25%22 stop-color=%22%23e0c3fc%22%2F%3E%3C%2FlinearGradient%3E%3CclipPath id=%22a%22%3E%3Cpath fill=%22currentColor%22 d=%22M799.5 671.5Q611 843 408.5 778T173 475.5q-33-237.5 199-267t424 131q192 160.5 3.5 332Z%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3Cg clip-path=%22url(%23a)%22%3E%3Cpath fill=%22url(%23b)%22 d=%22M799.5 671.5Q611 843 408.5 778T173 475.5q-33-237.5 199-267t424 131q192 160.5 3.5 332Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}
