.skills{
    height: 30vh;
    /* margin-top: 5%; */
    overflow: hidden;
    white-space: nowrap;
    /* margin-top: 1%; */
}
.skills h1{
    font-size: 0.8em;
    font-weight: 700;
    color: #281950;
}

.skills-row{
    margin-top: 2%;
    display: inline-block;
    animation: 20s slide infinite linear;
}
.skills-row img{
    width: 3em;
    margin: 0 40px;
    display: inline-block;
}

@keyframes slide {
    from{
        transform: translateX(0);
    }to{
        transform: translateX(-50%);
    }
}
