.logo {
  font-size: 1.5em;
  font-weight: 900;
}
.nav {
  margin-top: 1.5em;
  align-items: center;
  justify-content: center;
}
.main-col-2-card-4{
    display: flex;
    flex-direction: row;
    /* margin-top: 30%; */
    align-items: center;
    justify-content: right;
    /* margin-right: 20%; */
  }
  .location-pin{
    width: 10px;
    height: 10px;
    color: #131313;
    background-color: green;
    border-radius: 100%;
    filter: blur(0.7px);
    -webkit-filter: blur(0.7px);
    margin-right: 5%;
  }
  .color{
    color: #6E06F2;
  }
  .menu{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: large;
    font-weight: 700;
  }